import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { ROUTES } from '../constants/routes';

const useAuth = () => {
  const navigate = useNavigate();
  const { setAuthData } = useContext(AuthContext);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const handleLogout = () => {
    setAuthData(null);
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('products');
    navigate(ROUTES.LOGIN);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return { handleLogout, isSidebarOpen, toggleSidebar };
};

export default useAuth;