export const ROUTES = {
    HOME: '/',
    LOGIN: '/login',
    DASHBOARD: '/dashboard',
    PRODUCTS: '/products',
    SALES: '/sales',
    SALE_ON_CREDIT: '/sale-on-credit',
    CUSTOMERS: '/customers',
    ADD_PRODUCT: '/add-product',
    ADD_PURCHASE: '/add-purchase',
    PURCHASES_BY_SUPPLIER: 'purchases-by-supplier',
    ADD_SALE: '/add-sale',
    INVENTORY: '/inventory',
    MOST_SOLD: '/most-sold',
    LEAST_SOLD: '/least-sold',
    EXPIRING_PRODUCTS: '/expiring-products'
  };