import React, { useState, useEffect, useContext } from 'react';
import {
  Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress,
  Box, Tooltip, IconButton, useTheme, useMediaQuery, Grid, Collapse, Button
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpirationChip from '../../utils/ExpirationChip';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(3),
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

const ProductCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const DescriptionButton = styled(Button)(({ theme }) => ({
  alignSelf: 'flex-start',
  marginTop: theme.spacing(1),
  textTransform: 'none',
}));

const ExpiringProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const { authData } = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  useEffect(() => {
    const fetchExpiringProducts = async () => {
      try {
        const response = await axios.get('https://app20240719163201.azurewebsites.net/api/Purchase/ProductToExpire?daysThreshold=14', {
          headers: { 'Authorization': `Bearer ${authData.token}` }
        });
        setProducts(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching expiring products:', error);
        setError('Failed to fetch expiring products. Please try again.');
        setLoading(false);
      }
    };

    fetchExpiringProducts();
  }, [authData.token]);

  const getDaysUntilExpiration = (expirationDate) => {
    const today = new Date();
    const expDate = new Date(expirationDate);
    const diffTime = expDate - today;
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const toggleDescription = (sku) => {
    setExpandedDescriptions(prev => ({
      ...prev,
      [sku]: !prev[sku]
    }));
  };

  if (loading) return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <CircularProgress size={60} />
    </Box>
  );

  if (error) return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Typography color="error" variant="h6" component="div">
        <ErrorOutlineIcon fontSize="large" style={{ verticalAlign: 'middle', marginRight: theme.spacing(1) }} />
        {error}
      </Typography>
    </Box>
  );

  const renderDesktopTable = () => (
    <StyledTableContainer component={Paper}>
      <Table>
        <StyledTableHead>
          <TableRow>
            <StyledHeaderCell>Nombre</StyledHeaderCell>
            <StyledHeaderCell>SKU</StyledHeaderCell>
            <StyledHeaderCell>Descripcion</StyledHeaderCell>
            <StyledHeaderCell align="right">Cantidad</StyledHeaderCell>
            <StyledHeaderCell align="right">Expiracion</StyledHeaderCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {products.length > 0 ? (
            products.map((product) => {
              const daysUntilExpiration = getDaysUntilExpiration(product.expirationDate);
              return (
                <React.Fragment key={product.sku}>
                  <StyledTableRow>
                    <StyledTableCell>{product.productName}</StyledTableCell>
                    <StyledTableCell>{product.sku}</StyledTableCell>
                    <StyledTableCell>
                      <Button
                        onClick={() => toggleDescription(product.sku)}
                        endIcon={expandedDescriptions[product.sku] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      >
                        Ver descripción
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell align="right">{product.quantity}</StyledTableCell>
                    <StyledTableCell align="right">
                      <ExpirationChip daysUntilExpiration={daysUntilExpiration} />
                    </StyledTableCell>
                  </StyledTableRow>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Collapse in={expandedDescriptions[product.sku]} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                          <Typography variant="body2">{product.description}</Typography>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                <Typography variant="body1" color="textSecondary">
                  Sin productos por expirar dentro de los proximos 14 dias
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );

  const renderMobileLayout = () => (
    <Grid container spacing={2}>
      {products.length > 0 ? (
        products.map((product) => {
          const daysUntilExpiration = getDaysUntilExpiration(product.expirationDate);
          return (
            <Grid item xs={12} key={product.sku}>
              <ProductCard elevation={2}>
                <Typography variant="h6">{product.productName}</Typography>
                <Typography variant="body2" color="textSecondary">SKU: {product.sku}</Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2">Cantidad: {product.quantity}</Typography>
                  <ExpirationChip daysUntilExpiration={daysUntilExpiration} />
                </Box>
                <DescriptionButton
                  onClick={() => toggleDescription(product.sku)}
                  endIcon={expandedDescriptions[product.sku] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                >
                  {expandedDescriptions[product.sku] ? 'Ocultar descripción' : 'Ver descripción'}
                </DescriptionButton>
                <Collapse in={expandedDescriptions[product.sku]} timeout="auto" unmountOnExit>
                  <Box marginTop={1}>
                    <Typography variant="body2">{product.description}</Typography>
                  </Box>
                </Collapse>
              </ProductCard>
            </Grid>
          );
        })
      ) : (
        <Grid item xs={12}>
          <Typography variant="body1" color="textSecondary" align="center">
            Sin productos por expirar dentro de los proximos 14 dias
          </Typography>
        </Grid>
      )}
    </Grid>
  );

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom color="primary" fontWeight="bold">
          Productos por expirar
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" paragraph>
          Mostrando productos con fecha de expiracion dentro de los proximos 14 dias
        </Typography>
      </Box>

      {isMobile || isTablet ? renderMobileLayout() : renderDesktopTable()}
    </Container>
  );
};

export default ExpiringProducts;