import React, { Suspense, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { CircularProgress, Box, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import Sidebar from './components/Layout/SideBar';
import Header from './components/Layout/Header';

import Login from './components/Auth/Login';
import ExpiringProducts from './components/Products/ExpiringProducts';
import MostSoldProducts from './components/Products/MostSoldProducts';
import LeastSoldProducts from './components/Products/LeastSoldProducts';
import AddPurchase from './components/Purchases/AddPurchase';
import PrivateRoute from './components/Auth/PrivateRoute';
import { AuthContext } from './context/AuthContext';
import AuthProvider from './context/AuthContext';
import { ROUTES } from './constants/routes';
import useAuth from './hooks/useAuth';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import './App.css';
import CustomerManagement from './components/Customers/CustomerManagement';

import PurchasesBySupplier from './components/Purchases/PurchasesBySupplier';
import AddCreditSale from './components/Sales/AddCreditSale';

// Lazy load components
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Products = React.lazy(() => import('./pages/Products'));
const Sales = React.lazy(() => import('./pages/Sales'));
const Users = React.lazy(() => import('./pages/Users'));
const AddProduct = React.lazy(() => import('./components/Products/AddProduct'));
const AddSale = React.lazy(() => import('./components/Sales/AddSale'));
const Inventory = React.lazy(() => import('./components/Inventory/Inventory'));


const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
      light: '#757de8',
      dark: '#002984',
    },
    secondary: {
      main: '#f50057',
      light: '#ff4081',
      dark: '#c51162',
    },
    background: {
      default: '#f4f6f8',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
        },
      },
    },
  },
});

const App = () => (
  <ThemeProvider theme={theme}>
  <LocalizationProvider dateAdapter={AdapterDateFns}>
  <Router>
    <AuthProvider>
      <MainApp />
    </AuthProvider>
  </Router>
  </LocalizationProvider>
  </ThemeProvider>
);

const MainApp = () => {
  const { authData, loading } = useContext(AuthContext);
  const { handleLogout, isSidebarOpen, toggleSidebar } = useAuth();

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', overflow: 'hidden' }}>
      <CssBaseline />
      {authData?.token ? (
        <AuthenticatedLayout
          onLogout={handleLogout}
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
        >
          <Suspense fallback={<LoadingScreen />}>
            <Routes>
              <Route path={ROUTES.HOME} element={<Navigate to={ROUTES.DASHBOARD} />} />
              <Route path={ROUTES.DASHBOARD} element={<PrivateRoute><Dashboard /></PrivateRoute>} />
              <Route path={ROUTES.PRODUCTS} element={<PrivateRoute><Products /></PrivateRoute>} />
              <Route path={ROUTES.SALES} element={<PrivateRoute><Sales /></PrivateRoute>} />
              <Route path={ROUTES.USERS} element={<PrivateRoute><Users /></PrivateRoute>} />
              <Route path={ROUTES.ADD_PRODUCT} element={<PrivateRoute><AddProduct /></PrivateRoute>} />
              <Route path={ROUTES.ADD_PURCHASE} element={<PrivateRoute><AddPurchase/></PrivateRoute>} />
              <Route path={ROUTES.CUSTOMERS} element={<PrivateRoute><CustomerManagement/></PrivateRoute>} />
              <Route path={ROUTES.ADD_SALE} element={<PrivateRoute><AddSale /></PrivateRoute>} />
              <Route path={ROUTES.SALE_ON_CREDIT} element={<PrivateRoute><AddCreditSale/></PrivateRoute>} />
              <Route path={ROUTES.INVENTORY} element={<PrivateRoute><Inventory /></PrivateRoute>} />
              <Route path={ROUTES.MOST_SOLD} element={<PrivateRoute><MostSoldProducts /></PrivateRoute>} />
              <Route path={ROUTES.LEAST_SOLD} element={<PrivateRoute><LeastSoldProducts /></PrivateRoute>} />
              <Route path={ROUTES.EXPIRING_PRODUCTS} element={<PrivateRoute><ExpiringProducts /></PrivateRoute>} />
              <Route path={ROUTES.PURCHASES_BY_SUPPLIER} element={<PrivateRoute><PurchasesBySupplier /></PrivateRoute>} />
            </Routes>
          </Suspense>
        </AuthenticatedLayout>
      ) : (
        <Routes>
          <Route path={ROUTES.LOGIN} element={<Login />} />
          <Route path="*" element={<Navigate to={ROUTES.LOGIN} />} />
        </Routes>
      )}
    </Box>
  );
};

const AuthenticatedLayout = ({ children, onLogout, isSidebarOpen, toggleSidebar }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
    <Header onLogout={onLogout} onMenuClick={toggleSidebar} />
    <Box sx={{ display: 'flex', flexGrow: 1, position: 'relative' }}>
      <Sidebar isOpen={isSidebarOpen} toggleDrawer={toggleSidebar} />
      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1, 
          p: 3, 
          width: '100%', 
          overflow: 'auto',
          mt: '64px', // Adjust this value based on your header height
          mb: '50px', // Add bottom margin to make space for the footer
        }}
      >
        {children}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: (theme) => theme.zIndex.drawer + 1, // Ensure it's above the sidebar
        }}
      >
        {/* <Footer /> */}
      </Box>
    </Box>
  </Box>
);

const LoadingScreen = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
    <CircularProgress />
  </Box>
);

export default App;