import React, { useState, useContext, useEffect, useMemo, useRef } from 'react';
import { 
  TextField, Button, Container, Box, Typography, Alert, Grid, Autocomplete,
  Paper, Snackbar, IconButton, CircularProgress, InputAdornment, Fade, Divider
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import { styled } from '@mui/system';
import debounce from 'lodash.debounce';
import { Checkbox, FormControlLabel } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const FormPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const FormTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
  },
}));

const ItemPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  position: 'relative',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 6px 12px rgba(0,0,0,0.1)',
  },
}));

const DeleteButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  color: theme.palette.error.main,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 4),
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
  },
}));

const AddCreditSale = () => {
  const [sale, setSale] = useState({
    saleDate: '',
    totalPrice: '',
    customerId: '',
    items: [{
      sku: '',
      name: '',
      description: '',
      quantity: '',
      unitPrice: '',
      isSoldByWeight: false,
      weightUnit: ''
    }]
  });
  const [formattedTotalPrice, setFormattedTotalPrice] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const { authData } = useContext(AuthContext);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [useCustomDate, setUseCustomDate] = useState(false);
  const [barcodeInput, setBarcodeInput] = useState('');
  const barcodeInputRef = useRef(null);

const formatDateForInput = (dateString) => {
  if (!dateString) return '';
  const [datePart] = dateString.split(' ');
  const [day, month, year] = datePart.split('-');
  return `${year}-${month}-${day}`;
};

const handleUseCustomDateChange = (event) => {
  setUseCustomDate(event.target.checked);
  if (!event.target.checked) {
    initializeSaleDate();
  }
};

const handleSaleDateChange = (e) => {
  const inputDate = e.target.value; // This will be in YYYY-MM-DD format
  const [year, month, day] = inputDate.split('-');
  const now = new Date();
  const formattedDate = `${day}-${month}-${year} ${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}`;
  setSale((prevSale) => ({ ...prevSale, saleDate: formattedDate }));
};
 

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      try {
        const [productsResponse, customersResponse] = await Promise.all([
          axios.get('https://app20240719163201.azurewebsites.net/api/Product', {
            headers: { 'Authorization': `Bearer ${authData.token}` }
          }),
          axios.get('https://app20240719163201.azurewebsites.net/api/Customer', {
            headers: { 'Authorization': `Bearer ${authData.token}` }
          })
          
        ]);
        console.log("customers", customersResponse.data)
        localStorage.setItem('products', JSON.stringify(productsResponse.data));
        setCustomers(customersResponse.data);
      } catch (error) {
        console.error('Error fetching initial data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
    initializeSaleDate();
  }, [authData.token]);

  const initializeSaleDate = () => {
    const now = new Date();
    const formattedDate = now.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    }).replace(/\//g, '-').replace(',', '');
    setSale((prevSale) => ({ ...prevSale, saleDate: formattedDate }));
  };

  useEffect(() => {
    const total = sale.items.reduce((acc, item) => {
      let itemPrice;
      if (item.isSoldByWeight && item.weightUnit === 'g') {
        // For gram-based products, calculate price based on quantity and price per 100g
        itemPrice = (parseFloat(item.quantity) / 100) * parseFloat(item.unitPrice);
      } else {
        itemPrice = parseFloat(item.quantity) * parseFloat(item.unitPrice);
      }
      return acc + (isNaN(itemPrice) ? 0 : itemPrice);
    }, 0);
    setSale((prevSale) => ({ ...prevSale, totalPrice: total }));
    setFormattedTotalPrice(formatChileanPesos(total));
  }, [sale.items]);

  const formatChileanPesos = (value) => {
    return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(value);
  };

  const handleChange = (e, index = null) => {
    const { name, value } = e.target;
    if (index !== null) {
      const newItems = sale.items.map((item, idx) =>
        idx === index ? { ...item, [name]: value } : item
      );
      setSale((prevSale) => ({ ...prevSale, items: newItems }));
    } else {
      setSale((prevSale) => ({ ...prevSale, [name]: value }));
    }
  };

  const handleBarcodeInput = (event) => {
    const barcode = event.target.value;
    setBarcodeInput(barcode);
  
    const products = JSON.parse(localStorage.getItem('products')) || [];
    const matchedProduct = products.find(product => product.barCode === barcode);
  
    if (matchedProduct) {
      setSale(prevSale => {
        const existingItemIndex = prevSale.items.findIndex(item => item.sku === matchedProduct.sku);
        
        if (existingItemIndex !== -1) {
          // If the item already exists, increment its quantity
          const updatedItems = prevSale.items.map((item, index) => 
            index === existingItemIndex 
              ? { ...item, quantity: (parseInt(item.quantity) + 1).toString() }
              : item
          );
          return { ...prevSale, items: updatedItems };
        } else {
          // If it's a new item, add it to the list
          const newItem = {
            sku: matchedProduct.sku,
            name: matchedProduct.name,
            description: matchedProduct.description,
            quantity: matchedProduct.isSoldByWeight ? '0' : '1',
            unitPrice: matchedProduct.unitPrice.toString(),
            isSoldByWeight: matchedProduct.isSoldByWeight,
            weightUnit: matchedProduct.isSoldByWeight ? matchedProduct.weightUnit : 'unit'
          };
          
          let updatedItems;
          if (prevSale.items[0].sku === '') {
            // If the first item is empty, replace it
            updatedItems = [newItem];
          } else {
            // Otherwise, add it as a new item
            updatedItems = [...prevSale.items, newItem];
          }
          return { ...prevSale, items: updatedItems };
        }
      });
  
      setBarcodeInput(''); // Clear the barcode input after processing
      
      if (barcodeInputRef.current) {
        barcodeInputRef.current.focus();
      }
    }
  };

  const handleAddItem = () => {
    setSale((prevSale) => ({
      ...prevSale,
      items: [...prevSale.items, { sku: '', name: '', description: '', quantity: '', unitPrice: '' }]
    }));
  };

  const handleRemoveItem = (index) => {
    setSale((prevSale) => ({
      ...prevSale,
      items: prevSale.items.filter((_, idx) => idx !== index)
    }));
  };
  const handleClear = () => {
    const now = new Date();
    const formattedDate = now.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    }).replace(/\//g, '-').replace(',', '');
    
    setSale(prevSale => ({
      saleDate: useCustomDate ? prevSale.saleDate : formattedDate,
      totalPrice: '',
      customerId: prevSale.customerId, // Preserve the customerId
      items: [{ sku: '', name: '', description: '', quantity: '', unitPrice: '' }]
    }));
    
    setFormattedTotalPrice('');
    setFilteredSuggestions([]);
  };

  const handleUnitPriceChange = (e, index) => {
    const rawValue = e.target.value.replace(/[^0-9.]/g, '');
    const newItems = sale.items.map((item, idx) => {
      if (idx === index) {
        return { ...item, unitPrice: rawValue };
      }
      return item;
    });
    setSale((prevSale) => ({ ...prevSale, items: newItems }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("salesState", sale);
    try {
      await axios.post(`https://app20240719163201.azurewebsites.net/api/Sale/credit?customerId=${sale.customerId}`, {
        saleDate: sale.saleDate,
        totalPrice: sale.totalPrice,
        customerId: sale.customerId, 
        items: sale.items.map(({ sku, quantity, unitPrice, isSoldByWeight, weightUnit }) => ({ 
          sku, 
          quantity: isSoldByWeight && weightUnit === 'g' ? parseFloat(quantity) / 1000 : parseFloat(quantity), 
          unitPrice: parseFloat(unitPrice),
          isSoldByWeight,
          weightUnit
        }))
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authData.token}`
        }
      });
      setSnackbar({ open: true, message: 'Credit sale added successfully.', severity: 'success' });
      handleClear();
    } catch (error) {
      setSnackbar({ open: true, message: 'There was an error adding the credit sale.', severity: 'error' });
    }
  };
  
  

  const debouncedFilterOptions = useMemo(
    () => debounce((inputValue) => {
      const products = JSON.parse(localStorage.getItem('products')) || [];
      const filtered = products.filter(option =>
        option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.description.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.sku.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    }, 300),
    []
  );

  const handleInputChange = (event, value) => {
    debouncedFilterOptions(value);
  };

  const handleSuggestionSelect = (event, value, index) => {
    if (value) {
      const newItems = sale.items.map((item, idx) =>
        idx === index ? { 
          sku: value.sku, 
          name: value.name,
          description: value.description, 
          quantity: value.isSoldByWeight ? '0' : '1', 
          unitPrice: value.unitPrice.toString(),
          isSoldByWeight: value.isSoldByWeight,
          weightUnit: value.isSoldByWeight ? value.weightUnit : 'unit'
        } : item
      );
      setSale((prevSale) => ({ ...prevSale, items: newItems }));
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StyledContainer maxWidth="md">
      <Fade in={true} timeout={1000}>
        <FormPaper elevation={3}>
          <FormTitle variant="h4">
            <CreditCardIcon fontSize="large" />
            Venta fiada a cliente registrado
          </FormTitle>
          <Divider sx={{ mb: 3 }} />
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
            <Grid item xs={12}>
  <FormControlLabel
    control={
      <Checkbox
        checked={useCustomDate}
        onChange={handleUseCustomDateChange}
        name="useCustomDate"
      />
    }
    label="Usar fecha personalizada"
  />
</Grid>
<Grid item xs={12}>
  {useCustomDate ? (
    <TextField
      label="Fecha de venta"
      name="saleDate"
      type="date"
      fullWidth
      value={formatDateForInput(sale.saleDate)}
      onChange={handleSaleDateChange}
      margin="normal"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        startAdornment: (
          <DateRangeIcon color="action" />
        ),
      }}
    />
  ) : (
    <TextField
      label="Fecha de venta"
      name="saleDate"
      fullWidth
      value={sale.saleDate}
      margin="normal"
      InputProps={{
        readOnly: true,
        startAdornment: (
          <DateRangeIcon color="action" />
        ),
      }}
    />
  )}
</Grid>
      
<Grid item xs={12}>
  <Autocomplete
    options={customers}
    getOptionLabel={(option) => `${option.name} (${option.phoneNumber})`}
    renderInput={(params) => <TextField {...params} label="Cliente" fullWidth />}
    onChange={(event, newValue) => {
      setSale(prevSale => ({ 
        ...prevSale, 
        customerId: newValue ? newValue.id : null
      }));
    }}
    isOptionEqualToValue={(option, value) => option.id === value.id}
  />
</Grid>

<Grid item xs={12}>
  <TextField
    label="Escanear código de barras"
    value={barcodeInput}
    onChange={handleBarcodeInput}
    fullWidth
    margin="normal"
    placeholder="Escanee o ingrese el código de barras"
    autoFocus
    inputRef={barcodeInputRef}
  />
</Grid>


            </Grid>

            <Typography variant="h6" gutterBottom sx={{ mt: 4, mb: 2 }}>Detalle venta</Typography>
            {sale.items.map((item, index) => (
  <Fade in={true} key={index} timeout={500}>
    <ItemPaper elevation={2}>
      <DeleteButton onClick={() => handleRemoveItem(index)} disabled={sale.items.length === 1}>
        <DeleteIcon />
      </DeleteButton>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            options={filteredSuggestions}
            getOptionLabel={(option) => `${option.name} - ${option.description}`}
            onInputChange={handleInputChange}
            onChange={(event, value) => handleSuggestionSelect(event, value, index)}
            value={item}
            isOptionEqualToValue={(option, value) => option.sku === value.sku}
            renderInput={(params) => (
              <TextField 
                {...params} 
                label="Nombre producto" 
                fullWidth 
                margin="normal" 
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Typography noWrap>
                  {option.name} - {option.description}
                </Typography>
              </li>
            )}
          />
        </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Descripcion"
                        name="description"
                        fullWidth
                        value={item.description}
                        onChange={(e) => handleChange(e, index)}
                        margin="normal"
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="SKU"
                        name="sku"
                        fullWidth
                        value={item.sku}
                        onChange={(e) => handleChange(e, index)}
                        margin="normal"
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
          <TextField
            label={item.isSoldByWeight ? `Cantidad (${item.weightUnit || 'kg'})` : "Cantidad"}
            name="quantity"
            type="text"
            fullWidth
            value={item.quantity}
            onChange={(e) => {
              const value = e.target.value;
              if (item.isSoldByWeight) {
                if (item.weightUnit === 'g') {
                  // Only allow whole numbers for grams
                  if (value === '' || /^\d+$/.test(value)) {
                    handleChange(e, index);
                  }
                } else {
                  // Allow decimal input for other weight-based products
                  if (value === '' || /^\d*\.?\d*$/.test(value)) {
                    handleChange(e, index);
                  }
                }
              } else {
                // Only allow integer input for non-weight-based products
                if (value === '' || /^\d+$/.test(value)) {
                  handleChange(e, index);
                }
              }
            }}
            onBlur={(e) => {
              // Format the value to 2 decimal places for weight-based products (except grams)
              if (item.isSoldByWeight && item.weightUnit !== 'g') {
                const formattedValue = parseFloat(e.target.value).toFixed(2);
                handleChange({ target: { name: 'quantity', value: formattedValue } }, index);
              }
            }}
            InputProps={{
              inputProps: { 
                min: 0,
                step: item.isSoldByWeight ? (item.weightUnit === 'g' ? 1 : 0.01) : 1
              }
            }}
            margin="normal"
            helperText={item.isSoldByWeight && item.weightUnit === 'g' ? "Ingrese la cantidad en gramos enteros" : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={
              item.isSoldByWeight
                ? item.weightUnit === 'g'
                  ? "Precio por 100grs"
                  : `Precio por ${item.weightUnit || 'kg'}`
                : "Precio unitario"
            }
            name="unitPrice"
            fullWidth
            value={item.unitPrice}
            onChange={(e) => handleUnitPriceChange(e, index)}
            margin="normal"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </Grid>
        {item.isSoldByWeight && (
          <Grid item xs={12} sm={6}>
            <TextField
              label="Precio total"
              fullWidth
              value={formatChileanPesos(
                item.weightUnit === 'g'
                  ? (parseFloat(item.quantity) / 100) * parseFloat(item.unitPrice)
                  : parseFloat(item.quantity) * parseFloat(item.unitPrice)
              )}
              InputProps={{ readOnly: true }}
              margin="normal"
            />
          </Grid>
        )}
      </Grid>
    </ItemPaper>
  </Fade>
))}
            <StyledButton
              startIcon={<AddIcon />}
              onClick={handleAddItem}
              variant="outlined"
              color="primary"
              fullWidth
              sx={{ mt: 2, mb: 4 }}
            >
              Agregar producto
            </StyledButton>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <StyledButton type="submit" variant="contained" color="primary" fullWidth>
                  Ingresar venta fiada
                </StyledButton>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledButton type="button" variant="outlined" color="secondary" fullWidth onClick={handleClear}>
                  Limpiar formulario
                </StyledButton>
              </Grid>
            </Grid>
          </Box>
        </FormPaper>
      </Fade>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
          </Alert>
      </Snackbar>
    </StyledContainer>
  );
};

export default AddCreditSale;