import React, { useState, useContext } from 'react';
import { 
  Container, TextField, Button, Box, Typography, Alert, Paper, InputAdornment, IconButton,
 Fade, CircularProgress
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';

const LoginBackground = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
  padding: theme.spacing(2),
}));

const LoginCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
  maxWidth: '400px',
  width: '100%',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
  },
}));

const Logo = styled(StorefrontOutlinedIcon)(({ theme }) => ({
  fontSize: '4rem',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[300],
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5),
  fontWeight: 'bold',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
  },
}));

const Login = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setAuthData } = useContext(AuthContext);
  const navigate = useNavigate();
  


  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    try {
      const response = await axios.post('https://app20240719163201.azurewebsites.net/api/Auth/login', credentials);
      setAuthData(response.data.token, credentials.username);
  
      const productsResponse = await axios.get('https://app20240719163201.azurewebsites.net/api/Product/Inventory', {
        headers: { 'Authorization': `Bearer ${response.data.token}` },
      });
      localStorage.setItem('products', JSON.stringify(productsResponse.data));
  
      navigate('/dashboard');
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            setError('Invalid username or password');
            break;
          case 403:
            if (err.response.data.code === 'INACTIVE_USER') {
              setError('Your account is not active. Please contact support.');
            } else {
              setError('Access denied. Please try again.');
            }
            break;
          default:
            setError('An error occurred. Please try again later.');
        }
      } else {
        setError('Unable to connect to the server. Please try again later.');
      }
      console.error('Login error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoginBackground>
      <Fade in={true} timeout={1000}>
        <Container maxWidth="sm">
          <LoginCard elevation={6}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Logo />
              <Typography variant="h4" gutterBottom align="center" fontWeight="bold" color="primary">
                Store Manager
              </Typography>
              <Typography variant="subtitle1" gutterBottom align="center" color="textSecondary">
                Efficient inventory and sales management
              </Typography>
            </Box>
            <Box component="form" onSubmit={handleSubmit} mt={4}>
              {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
              <StyledTextField
                label="Username"
                name="username"
                fullWidth
                value={credentials.username}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineOutlinedIcon color="action" />
                    </InputAdornment>
                  ),
                }}
              />
              <StyledTextField
                label="Password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                value={credentials.password}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon color="action" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <StyledButton 
                type="submit" 
                variant="contained" 
                color="primary" 
                fullWidth 
                size="large"
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
              </StyledButton>
            </Box>
          </LoginCard>
        </Container>
      </Fade>
    </LoginBackground>
  );
};

export default Login;