import React, { useState, useContext, useEffect, useMemo, useRef } from 'react';
import { 
  TextField, Button, Container, Box, Typography, Alert, Grid, Autocomplete,
  Paper, Snackbar, IconButton, CircularProgress, Fade, Divider, Checkbox, FormControlLabel,
  FormGroup, useMediaQuery, useTheme
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DateRangeIcon from '@mui/icons-material/DateRange';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import { styled } from '@mui/system';
import debounce from 'lodash.debounce';
import {InputAdornment} from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const FormPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const FormTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
  },
}));

const ItemPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  position: 'relative',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 6px 12px rgba(0,0,0,0.1)',
  },
}));

const DeleteButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  color: theme.palette.error.main,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 4),
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
  },
}));


const StyledDateField = styled(TextField)(({ theme }) => ({
  '& .MuiInputAdornment-root': {
    marginRight: theme.spacing(1),
  },
  '& .MuiInputBase-input': {
    padding: theme.spacing(2),
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  marginTop: theme.spacing(1),
}));
const AddPurchase = () => {
const [purchase, setPurchase] = useState({
  purchaseDate: '',
  totalPrice: '',
  billNumber: '',
  supplier: '',
  items: [{
    sku: '',
    productName: '',
    description: '',
    quantity: '',
    unitPrice: '',
    expirationDate: '',
    noExpirationDate: false,
    isSoldByWeight: false,
    weightUnit: ''
  }]
});
  const [formattedTotalPrice, setFormattedTotalPrice] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const { authData } = useContext(AuthContext);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [useCustomDate, setUseCustomDate] = useState(false);
  const [barcodeInput, setBarcodeInput] = useState('');
  const barcodeInputRef = useRef(null);
  

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://app20240719163201.azurewebsites.net/api/Product', {
          headers: {
            'Authorization': `Bearer ${authData.token}`
          }
        });
        localStorage.setItem('products', JSON.stringify(response.data));
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
    initializePurchaseDate();
  }, [authData.token]);

  const initializePurchaseDate = () => {
    const now = new Date();
    const formattedDate = now.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    }).replace(/\//g, '-').replace(',', '');
    setPurchase((prevPurchase) => ({ ...prevPurchase, purchaseDate: formattedDate }));
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const [datePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('-');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };
  
  const handlePurchaseDateChange = (e) => {
    const inputDate = e.target.value; // This will be in YYYY-MM-DD format
    const [year, month, day] = inputDate.split('-');
    const now = new Date();
    const formattedDate = `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year} ${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}`;
    setPurchase((prevPurchase) => ({ ...prevPurchase, purchaseDate: formattedDate }));
  };
  const handleBarcodeInput = (event) => {
    const barcode = event.target.value;
    setBarcodeInput(barcode);
  
    const products = JSON.parse(localStorage.getItem('products')) || [];
    const matchedProduct = products.find(product => product.barCode === barcode);
  
    if (matchedProduct) {
      setPurchase(prevPurchase => {
        const existingItemIndex = prevPurchase.items.findIndex(item => item.sku === matchedProduct.sku);
        
        if (existingItemIndex !== -1) {
          const updatedItems = prevPurchase.items.map((item, index) => 
            index === existingItemIndex 
              ? { ...item, quantity: (parseFloat(item.quantity) + 1).toString() }
              : item
          );
          return { ...prevPurchase, items: updatedItems };
        } else {
          const newItem = {
            sku: matchedProduct.sku,
            productName: matchedProduct.name,
            description: matchedProduct.description,
            quantity: matchedProduct.isSoldByWeight ? '0' : '1',
            unitPrice: matchedProduct.unitPrice.toString(),
            expirationDate: '',
            noExpirationDate: false,
            isSoldByWeight: matchedProduct.isSoldByWeight,
            weightUnit: matchedProduct.isSoldByWeight ? matchedProduct.weightUnit : ''
          };
          
          let updatedItems;
          if (prevPurchase.items[0].sku === '') {
            updatedItems = [newItem];
          } else {
            updatedItems = [newItem, ...prevPurchase.items];
          }
          return { ...prevPurchase, items: updatedItems };
        }
      });
  
      setBarcodeInput('');
      if (barcodeInputRef.current) {
        barcodeInputRef.current.focus();
      }
    }
  };


  const handleUseCustomDateChange = (event) => {
    setUseCustomDate(event.target.checked);
    if (!event.target.checked) {
      initializePurchaseDate();
    }
  };
  useEffect(() => {
    const total = purchase.items.reduce((acc, item) => {
      let itemPrice;
      if (item.isSoldByWeight && item.weightUnit === 'g') {
        // For gram-based products, calculate price based on quantity and price per 100g
        itemPrice = (parseFloat(item.quantity) / 100) * parseFloat(item.unitPrice);
      } else {
        itemPrice = parseFloat(item.quantity) * parseFloat(item.unitPrice);
      }
      return acc + (isNaN(itemPrice) ? 0 : itemPrice);
    }, 0);
    setPurchase((prevPurchase) => ({ ...prevPurchase, totalPrice: total }));
    setFormattedTotalPrice(formatChileanPesos(total));
  }, [purchase.items]);

  const formatChileanPesos = (value) => {
    return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(value);
  };

  const handleChange = (e, index = null) => {
    const { name, value } = e.target;
    if (index !== null) {
      const newItems = purchase.items.map((item, idx) =>
        idx === index ? { ...item, [name]: value } : item
      );
      setPurchase((prevPurchase) => ({ ...prevPurchase, items: newItems }));
    } else {
      setPurchase((prevPurchase) => ({ ...prevPurchase, [name]: value }));
    }
  };
  const handleAddItem = () => {
    setPurchase((prevPurchase) => ({
      ...prevPurchase,
      items: [...prevPurchase.items, { sku: '', productName: '', description: '', quantity: '', unitPrice: '', expirationDate: '' }]
    }));
  };

  const handleRemoveItem = (index) => {
    setPurchase((prevPurchase) => ({
      ...prevPurchase,
      items: prevPurchase.items.filter((_, idx) => idx !== index)
    }));
  };

  const handleClear = () => {
    const now = new Date();
    const formattedDate = now.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    }).replace(/\//g, '-').replace(',', '');
    setPurchase({
      purchaseDate: formattedDate,
      totalPrice: '',
      billNumber: '',
      supplier: '', // Add this line
      items: [{ sku: '', productName: '', description: '', quantity: '', unitPrice: '', expirationDate: '', noExpirationDate: false }]
    });
  };

  const handleUnitPriceChange = (e, index) => {
    const rawValue = e.target.value.replace(/[^0-9]/g, '');
    const newItems = purchase.items.map((item, idx) =>
      idx === index ? { ...item, unitPrice: rawValue } : item
    );
    setPurchase((prevPurchase) => ({ ...prevPurchase, items: newItems }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formatDate = (dateString, noExpiration) => {
        if (noExpiration) {
          return '9999-12-31'; // Far future date
        }
        if (!dateString) return null;
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }).replace(/\//g, '-');
      };
  
      const isValid = purchase.items.every(item => 
        item.sku && item.productName && item.quantity && item.unitPrice && (item.expirationDate || item.noExpirationDate)
      );
    
      if (!isValid) {
        setSnackbar({ open: true, message: 'Please fill all required fields for each item.', severity: 'error' });
        return;
      }
  
      const formattedPurchase = {
        purchaseDate: purchase.purchaseDate,
        totalPrice: parseFloat(purchase.totalPrice),
        billNumber: purchase.billNumber,
        supplier: purchase.supplier.toLowerCase(),
        purchaseItems: purchase.items.map(({ sku, productName, description, quantity, unitPrice, expirationDate, noExpirationDate, isSoldByWeight, weightUnit }) => ({ 
          sku, 
          productName,
          description,
          quantity: isSoldByWeight ? parseFloat(quantity) : parseInt(quantity), 
          unitPrice: parseFloat(unitPrice),
          expirationDate: formatDate(expirationDate, noExpirationDate),
          isSoldByWeight,
          weightUnit
        }))
      };
  
      const response = await axios.post('https://app20240719163201.azurewebsites.net/api/Purchase', formattedPurchase, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authData.token}`
        }
      });
  
      console.log('Response:', response.data);
      setSnackbar({ open: true, message: 'Purchase added successfully.', severity: 'success' });
      handleClear();
    } catch (error) {
      console.error('Error adding purchase:', error);
      setSnackbar({ open: true, message: 'There was an error adding the purchase.', severity: 'error' });
    }
  };
  const debouncedFilterOptions = useMemo(
    () => debounce((inputValue) => {
      const products = JSON.parse(localStorage.getItem('products')) || [];
      const filtered = products.filter(option =>
        option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.description.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.sku.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    }, 300),
    []
  );

  const handleInputChange = (event, value) => {
    debouncedFilterOptions(value);
  };

  const handleSuggestionSelect = (event, value, index) => {
    if (value) {
      const newItems = purchase.items.map((item, idx) =>
        idx === index ? { 
          ...item,
          sku: value.sku, 
          productName: value.name,
          description: value.description, 
          quantity: value.isSoldByWeight ? '0' : '1', 
          unitPrice: '', // Remove the suggested price
          isSoldByWeight: value.isSoldByWeight,
          weightUnit: value.isSoldByWeight ? value.weightUnit : 'unit'
        } : item
      );
      setPurchase((prevPurchase) => ({ ...prevPurchase, items: newItems }));
    }
  };


const handleNoExpirationDateChange = (e, index) => {
  const { checked } = e.target;
  const newItems = purchase.items.map((item, idx) =>
    idx === index ? { 
      ...item, 
      noExpirationDate: checked,
      expirationDate: checked ? '' : item.expirationDate 
    } : item
  );
  setPurchase((prevPurchase) => ({ ...prevPurchase, items: newItems }));
};

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StyledContainer maxWidth="md">
    <Fade in={true} timeout={1000}>
      <FormPaper elevation={3}>
        <FormTitle variant="h4">
          <ShoppingCartIcon fontSize="large" />
          Ingresar compra
        </FormTitle>
        <Divider sx={{ mb: 3 }} />
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={useCustomDate}
                    onChange={handleUseCustomDateChange}
                    name="useCustomDate"
                  />
                }
                label="Usar fecha personalizada"
              />
            </Grid>
            <Grid item xs={12}>
              {useCustomDate ? (
                <StyledDateField
                  label="Fecha de compra"
                  name="purchaseDate"
                  type="date"
                  fullWidth
                  value={formatDateForInput(purchase.purchaseDate)}
                  onChange={handlePurchaseDateChange}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: (
                      <DateRangeIcon color="action" />
                    ),
                  }}
                />
              ) : (
                <TextField
                  label="Fecha de compra"
                  name="purchaseDate"
                  fullWidth
                  value={purchase.purchaseDate}
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <DateRangeIcon color="action" />
                    ),
                  }}
                />
              )}
            </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Numero de documento(boleta o factura)"
                  name="billNumber"
                  fullWidth
                  value={purchase.billNumber}
                  onChange={handleChange}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Proveedor"
                  name="supplier"
                  fullWidth
                  value={purchase.supplier}
                  onChange={handleChange}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
  <TextField
    label="Escanear código de barras"
    value={barcodeInput}
    onChange={handleBarcodeInput}
    fullWidth
    margin="normal"
    placeholder="Escanee o ingrese el código de barras"
    autoFocus
    inputRef={barcodeInputRef}
  />
</Grid>
            </Grid>

            <Typography variant="h6" gutterBottom sx={{ mt: 4, mb: 2 }}>Detalle compra</Typography>
            {purchase.items.map((item, index) => (
  <Fade in={true} key={index} timeout={500}>
    <ItemPaper elevation={2}>
      <DeleteButton onClick={() => handleRemoveItem(index)} disabled={purchase.items.length === 1}>
        <DeleteIcon />
      </DeleteButton>
      <Grid container spacing={2}>
      <Grid item xs={12}>
  <Autocomplete
    options={filteredSuggestions}
    getOptionLabel={(option) => {
      // If option is a string, return it as is
      if (typeof option === 'string') return option;
      // If it's an object (from filteredSuggestions), return the name
      return option.name || '';
    }}
    onInputChange={handleInputChange}
    onChange={(event, value) => handleSuggestionSelect(event, value, index)}
    value={item.productName || null}
    isOptionEqualToValue={(option, value) => option.sku === value.sku}
    renderInput={(params) => (
      <TextField 
        {...params} 
        label="Nombre del producto" 
        fullWidth 
        margin="normal"
        value={item.productName}
        onChange={(e) => handleChange({
          target: {
            name: 'productName',
            value: e.target.value
          }
        }, index)}
      />
    )}
    renderOption={(props, option) => (
      <li {...props}>
        <Typography noWrap>
          {option.name} - {option.description}
        </Typography>
      </li>
    )}
  />
</Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Descripcion"
            name="description"
            fullWidth
            value={item.description}
            onChange={(e) => handleChange(e, index)}
            margin="normal"
            InputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="SKU"
            name="sku"
            fullWidth
            value={item.sku}
            onChange={(e) => handleChange(e, index)}
            margin="normal"
            InputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={item.isSoldByWeight ? `Cantidad (${item.weightUnit || 'kg'})` : "Cantidad"}
            name="quantity"
            type="text"
            fullWidth
            value={item.quantity}
            onChange={(e) => {
              const value = e.target.value;
              if (item.isSoldByWeight) {
                if (item.weightUnit === 'g') {
                  // Only allow whole numbers for grams
                  if (value === '' || /^\d+$/.test(value)) {
                    handleChange(e, index);
                  }
                } else {
                  // Allow decimal input for other weight-based products
                  if (value === '' || /^\d*\.?\d*$/.test(value)) {
                    handleChange(e, index);
                  }
                }
              } else {
                // Only allow integer input for non-weight-based products
                if (value === '' || /^\d+$/.test(value)) {
                  handleChange(e, index);
                }
              }
            }}
            InputProps={{
              inputProps: { 
                min: 0,
                step: item.isSoldByWeight ? (item.weightUnit === 'g' ? 1 : 0.01) : 1
              }
            }}
            margin="normal"
            helperText={item.isSoldByWeight && item.weightUnit === 'g' ? "Ingrese la cantidad en gramos enteros" : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
              <TextField
                label={
                  item.isSoldByWeight
                    ? item.weightUnit === 'g'
                      ? "Precio de costo por 100grs"
                      : `Precio de costo por ${item.weightUnit || 'kg'}`
                    : "Precio de costo unitario"
                }
                name="unitPrice"
                fullWidth
                value={item.unitPrice}
                onChange={(e) => handleUnitPriceChange(e, index)}
                margin="normal"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </Grid>
        {item.isSoldByWeight && (
          <Grid item xs={12} sm={6}>
            <TextField
              label="Precio total"
              fullWidth
              value={formatChileanPesos(
                item.weightUnit === 'g'
                  ? (parseFloat(item.quantity) / 100) * parseFloat(item.unitPrice)
                  : parseFloat(item.quantity) * parseFloat(item.unitPrice)
              )}
              InputProps={{ readOnly: true }}
              margin="normal"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'flex-start', gap: 2 }}>
            <StyledDateField
              label="Fecha de expiración"
              name="expirationDate"
              type="date"
              fullWidth={isMobile}
              required={!item.noExpirationDate}
              value={item.expirationDate}
              onChange={(e) => handleChange(e, index)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <DateRangeIcon color="action" />
                ),
              }}
              disabled={item.noExpirationDate}
            />
            <FormGroup>
              <StyledFormControlLabel
                control={
                  <Checkbox
                    checked={item.noExpirationDate}
                    onChange={(e) => handleNoExpirationDateChange(e, index)}
                    name="noExpirationDate"
                  />
                }
                label="Sin expiración"
              />
            </FormGroup>
          </Box>
        </Grid>
      </Grid>
    </ItemPaper>
  </Fade>
))}
            
            <StyledButton
              startIcon={<AddIcon />}
              onClick={handleAddItem}
              variant="outlined"
              color="primary"
              fullWidth
              sx={{ mt: 2, mb: 4 }}
            >
              Agregar producto
            </StyledButton>

            <Typography variant="h6" align="right" gutterBottom>
              Total: {formattedTotalPrice}
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <StyledButton type="submit" variant="contained" color="primary" fullWidth>
                  Ingresar compra
                </StyledButton>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledButton type="button" variant="outlined" color="secondary" fullWidth onClick={handleClear}>
                  Limpiar formulario
                </StyledButton>
              </Grid>
            </Grid>
          </Box>
        </FormPaper>
      </Fade>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </StyledContainer>
  );
};

export default AddPurchase;