import React, { useState, useContext } from 'react';
import {
  Container, Typography, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, CircularProgress, Box, Collapse, IconButton, InputAdornment, Fade, useTheme, useMediaQuery,
  Card, CardContent, Grid, Divider
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { alpha } from '@mui/material';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(3),
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  borderRadius: theme.shape.borderRadius,
  overflow: 'auto',
  maxHeight: 'calc(100vh - 300px)', // Adjust based on your layout
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '& .MuiTableCell-head': {
    color: theme.palette.common.black,
    fontWeight: 'bold',
    fontSize: '1rem',
    backgroundColor: theme.palette.grey[200], // Light grey background
  },
}));


// For the detail table headers (in expanded rows and cards)
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1, 3),
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
}));

const ResponsiveBox = styled(Box)(({ theme }) => ({
  overflowX: 'auto',
  'WebkitOverflowScrolling': 'touch',
}));

const PurchasesBySupplier = () => {
  const [supplier, setSupplier] = useState('');
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedPurchase, setExpandedPurchase] = useState(null);
  const { authData } = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`https://app20240719163201.azurewebsites.net/api/Purchase/supplier/${supplier}`, {
        headers: { 'Authorization': `Bearer ${authData.token}` }
      });
      setPurchases(response.data);
      console.log("salesbysupplier", response.data)
    } catch (err) {
      console.error('Error fetching purchases:', err);
      setError('Failed to fetch purchases. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString();
    return formattedDate === '31/12/1969' ? 'Sin expiracion' : formattedDate;
  };

  const formatQuantity = (item) => {
    if (item.isSoldByWeight) {
      return `${item.quantity} ${item.weightUnit}`;
    }
    return Math.round(item.quantity).toString();
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CL', { 
      style: 'currency', 
      currency: 'CLP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  };

  const formatUnitPrice = (item) => {
    if (item.isSoldByWeight) {
      if (item.weightUnit === 'g') {
        // For gram-based products, show price per 100g
        return `${formatCurrency(item.unitPrice)}/100g`;
      } else {
        // For kilogram-based products, show price per kg
        return `${formatCurrency(item.unitPrice)}/kg`;
      }
    }
    // For non-weight-based products, show regular unit price
    return formatCurrency(item.unitPrice);
  };

  

  const renderPurchaseCard = (purchase) => (
    <StyledCard key={purchase.id}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Fecha compra: {formatDate(purchase.purchaseDate)}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Numero documento: {purchase.billNumber}
        </Typography>
        <Typography variant="h6" color="primary" gutterBottom>
          Precio total: {formatCurrency(purchase.totalPrice)}
        </Typography>
        <Button
          onClick={() => setExpandedPurchase(expandedPurchase === purchase.id ? null : purchase.id)}
          startIcon={expandedPurchase === purchase.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        >
          {expandedPurchase === purchase.id ? 'Ocultar detalles' : 'Ver detalles'}
        </Button>
        <Collapse in={expandedPurchase === purchase.id} timeout="auto" unmountOnExit>
          <Box mt={2}>
            <Typography variant="h6" gutterBottom>Detalle compra</Typography>
            <Divider />
            <ResponsiveBox>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Producto</TableCell>
                    <TableCell>SKU</TableCell>
                    <TableCell align="right">Cantidad</TableCell>
                    <TableCell align="right">Precio unitario</TableCell>
                    <TableCell>Expiración</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {purchase.purchaseItems.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.productName}</TableCell>
                      <TableCell>{item.sku}</TableCell>
                      <TableCell align="right">{formatQuantity(item)}</TableCell>
                      <TableCell align="right">{formatUnitPrice(item)}</TableCell>
                      <TableCell>{formatDate(item.expirationDate)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </ResponsiveBox>
          </Box>
        </Collapse>
      </CardContent>
    </StyledCard>
  );

  return (
    <StyledContainer maxWidth="lg">
      <Fade in={true} timeout={1000}>
        <Box>
          <Typography variant="h4" component="h1" gutterBottom color="primary" fontWeight="bold" align={isMobile ? "center" : "left"}>
            <ShoppingCartIcon fontSize="large" style={{ verticalAlign: 'middle', marginRight: theme.spacing(1) }} />
            Buscar compras por proveedor
          </Typography>
          <StyledPaper>
            <TextField
              fullWidth
              label="Nombre del proveedor"
              value={supplier.toLowerCase()}
              onChange={(e) => setSupplier(e.target.value.toLowerCase())}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
              }}
            />
            <Box display="flex" justifyContent={isMobile ? "center" : "flex-start"}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={handleSearch}
                disabled={!supplier || loading}
                fullWidth={isMobile}
              >
                Buscar
              </StyledButton>
            </Box>
          </StyledPaper>

          {loading && (
            <Box display="flex" justifyContent="center" my={4}>
              <CircularProgress size={60} />
            </Box>
          )}

          {error && (
            <Box display="flex" justifyContent="center" my={4}>
              <Typography color="error" variant="h6" align="center">
                <ErrorOutlineIcon fontSize="large" style={{ verticalAlign: 'middle', marginRight: theme.spacing(1) }} />
                {error}
              </Typography>
            </Box>
          )}

{!loading && !error && purchases.length > 0 && (
            <ResponsiveBox>
              {(isMobile || isTablet) ? (
                purchases.map(renderPurchaseCard)
              ) : (
                <StyledTableContainer component={Paper}>
                  <Table stickyHeader>
                    <StyledTableHead>
                      <TableRow>
                        <TableCell>Fecha compra</TableCell>
                        <TableCell>Numero documento</TableCell>
                        <TableCell align="right">Precio total</TableCell>
                        <TableCell align="center">Acciones</TableCell>
                      </TableRow>
                    </StyledTableHead>
                    <TableBody>
                      {purchases.map((purchase) => (
                        <React.Fragment key={purchase.id}>
                          <StyledTableRow>
                            <TableCell>{formatDate(purchase.purchaseDate)}</TableCell>
                            <TableCell>{purchase.billNumber}</TableCell>
                            <TableCell align="right">{formatCurrency(purchase.totalPrice)}</TableCell>
                            <TableCell align="center">
                              <IconButton
                                onClick={() => setExpandedPurchase(expandedPurchase === purchase.id ? null : purchase.id)}
                              >
                                {expandedPurchase === purchase.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                              </IconButton>
                            </TableCell>
                          </StyledTableRow>
                          <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                              <Collapse in={expandedPurchase === purchase.id} timeout="auto" unmountOnExit>
                                <Box margin={1}>
                                  <Typography variant="h6" gutterBottom component="div">
                                    Detalle compra
                                  </Typography>
                                  <Table size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Nombre producto</TableCell>
                                        <TableCell>SKU</TableCell>
                                        <TableCell align="right">Cantidad</TableCell>
                                        <TableCell align="right">Precio unitario</TableCell>
                                        <TableCell>Fecha de expiracion</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {purchase.purchaseItems.map((item) => (
                                        <TableRow key={item.id}>
                                          <TableCell component="th" scope="row">
                                            {item.productName}
                                          </TableCell>
                                          <TableCell>{item.sku}</TableCell>
                                          <TableCell align="right">{formatQuantity(item)}</TableCell>
                                          <TableCell align="right">{formatUnitPrice(item)}</TableCell>
                                          <TableCell>{formatDate(item.expirationDate)}</TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              )}
            </ResponsiveBox>
          )}

          {!loading && !error && purchases.length === 0 && (
            <Typography variant="h6" align="center" color="textSecondary" style={{ marginTop: theme.spacing(4) }}>
              Sin informacion
            </Typography>
          )}
        </Box>
      </Fade>
    </StyledContainer>
  );
};

export default PurchasesBySupplier;