import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Typography, Box, useMediaQuery, Collapse, IconButton } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { Link, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PeopleIcon from '@mui/icons-material/People';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InventoryIcon from '@mui/icons-material/Inventory';

import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';


const drawerWidth = 280;

const DrawerPaper = styled('div')(({ theme }) => ({
  width: drawerWidth,
  maxWidth: '100vw',
  background: '#1E1E1E',
  color: '#FFFFFF',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'radial-gradient(circle at 50% 50%, rgba(29, 78, 216, 0.15), transparent 80%)',
    pointerEvents: 'none',
  },
}));

const Logo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
  position: 'relative',
  overflow: 'hidden',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: '10%',
    width: '80%',
    height: '1px',
    background: 'linear-gradient(to right, transparent, #3B82F6, transparent)',
  },
}));

const LogoText = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 800,
  letterSpacing: '0.1em',
  background: 'linear-gradient(45deg, #60A5FA, #3B82F6)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
}));

const MenuSection = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

const StyledListItem = styled(ListItem)(({ theme, active }) => ({
  margin: theme.spacing(0.5, 1),
  borderRadius: '12px',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  position: 'relative',
  overflow: 'hidden',
  '&:hover': {
    background: 'rgba(59, 130, 246, 0.1)',
    transform: 'translateY(-2px)',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '4px',
    height: '100%',
    background: '#3B82F6',
    transform: active ? 'scaleY(1)' : 'scaleY(0)',
    transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  },
  ...(active && {
    background: 'rgba(59, 130, 246, 0.1)',
    '&::before': {
      transform: 'scaleY(1)',
    },
  }),
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: '#60A5FA',
  minWidth: '40px',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    fontSize: '0.95rem',
    fontWeight: 500,
    letterSpacing: '0.03em',
  },
}));

const ScrollBox = styled(Box)({
  overflowY: 'auto',
  overflowX: 'hidden',
  flexGrow: 1,
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#3B82F6',
    borderRadius: '2px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(59, 130, 246, 0.1)',
  },
});



const ExpandButton = styled(IconButton)(({ theme, open }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  color: '#60A5FA',
  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: 'transform 0.3s ease',
}));

const Sidebar = ({ isOpen, toggleDrawer }) => {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [productsOpen, setProductsOpen] = useState(true);
  const [salesOpen, setSalesOpen] = useState(true);

  const handleProductsClick = () => setProductsOpen(!productsOpen);
  const handleSalesClick = () => setSalesOpen(!salesOpen);

  const menuItems = [
    { text: 'Panel General', icon: <DashboardIcon />, path: '/dashboard' },
    {
      text: 'Productos',
      icon: <InventoryIcon />,
      subItems: [
        { text: 'Agregar Producto(s)', icon: <AddCircleIcon />, path: '/add-product' },
        { text: 'Mas vendidos', icon: <TrendingUpIcon />, path: '/most-sold' },
        { text: 'Menos vendidos', icon: <TrendingDownIcon />, path: '/least-sold' },
        { text: 'Productos por vencer', icon: <AccessTimeIcon />, path: '/expiring-products' },
        { text: 'Inventario', icon: <InventoryIcon />, path: '/inventory' },
      ],
    },
    {
      text: 'Compras',
      icon: <ShoppingCartIcon />,
      subItems: [
        { text: 'Agregar compra', icon: <AddIcon />, path: '/add-purchase' },
        { text: 'Compras por proveedor', icon: <SearchIcon />, path: '/purchases-by-supplier' },
      ],
    },
    {
      text: 'Ventas',
      icon: <ReceiptIcon />,
      subItems: [
        { text: 'Reporte de ventas', icon: <ReceiptIcon />, path: '/sales' },
        { text: 'Venta fiada', icon: <ReceiptIcon />, path: '/sale-on-credit' },
      ],
    },
    { text: 'Clientes', icon: <PeopleIcon />, path: '/customers' },
  ];

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          maxWidth: '100vw',
          boxSizing: 'border-box',
          overflowX: 'hidden',
        },
      }}
      variant={isMobile ? "temporary" : "permanent"}
      anchor="left"
      open={isOpen}
      onClose={toggleDrawer}
    >
      <DrawerPaper>
        <ExpandButton open={isOpen} onClick={toggleDrawer}>
          <MenuOpenIcon />
        </ExpandButton>
        <Logo>
          <LogoText/>
        </Logo>
        <ScrollBox>
          {menuItems.map((item) => (
            <MenuSection key={item.text}>
              {item.subItems ? (
                <>
                  <StyledListItem button onClick={item.text === 'Products' ? handleProductsClick : handleSalesClick}>
                    <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                    <StyledListItemText primary={item.text} />
                  </StyledListItem>
                  <Collapse in={item.text === 'Products' ? productsOpen : salesOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.subItems.map((subItem) => (
                        <StyledListItem
                          button
                          key={subItem.text}
                          component={Link}
                          to={subItem.path}
                          active={location.pathname === subItem.path ? 1 : 0}
                          sx={{ pl: 4 }}
                        >
                          <StyledListItemIcon>{subItem.icon}</StyledListItemIcon>
                          <StyledListItemText primary={subItem.text} />
                        </StyledListItem>
                      ))}
                    </List>
                  </Collapse>
                </>
              ) : (
                <StyledListItem
                  button
                  component={Link}
                  to={item.path}
                  active={location.pathname === item.path ? 1 : 0}
                >
                  <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                  <StyledListItemText primary={item.text} />
                </StyledListItem>
              )}
            </MenuSection>
          ))}
        </ScrollBox>
      </DrawerPaper>
    </Drawer>
  );
};

export default Sidebar;