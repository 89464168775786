import React, { useState, useEffect, useContext, useCallback } from 'react';
import { 
  Container, Typography, TextField, Button, List, ListItem, ListItemText, 
  Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Alert,
  Paper, Grid, IconButton, Box, Fade, CircularProgress, InputAdornment, Tooltip,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TableSortLabel
} from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import MoneyIcon from '@mui/icons-material/Money';
import SearchIcon from '@mui/icons-material/Search';
import HistoryIcon from '@mui/icons-material/History';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#f5f5f5',
    },
  },
});

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  background: 'linear-gradient(to bottom right, #ffffff, #f8f8f8)',
}));






const CustomerManagement = () => {
  const [customers, setCustomers] = useState([]);
  const [newCustomer, setNewCustomer] = useState({ name: '', contactInfo: '', debt: 0 });
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [newDebt, setNewDebt] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const { authData } = useContext(AuthContext);
  const [paymentAmount, setPaymentAmount] = useState('');
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [openPaymentHistoryDialog, setOpenPaymentHistoryDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');
  const [totalDebt, setTotalDebt] = useState(0);
  const [confirmDialog, setConfirmDialog] = useState({ open: false, title: '', content: '', action: null });
  const [addingCustomer, setAddingCustomer] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);
  const [originalCustomer, setOriginalCustomer] = useState(null);

  const fetchCustomers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://app20240719163201.azurewebsites.net/api/Customer', {
        headers: { 'Authorization': `Bearer ${authData.token}` }
      });
      setCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
      setSnackbar({ open: true, message: 'Error al traer lista de clientes', severity: 'error' });
    } finally {
      setLoading(false);
    }
  }, [authData.token]); // Add authData.token to the dependency array

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]); // Include fetchCustomers in the dependency array

  useEffect(() => {
    const total = customers.reduce((sum, customer) => sum + customer.debt, 0);
    setTotalDebt(total);
  }, [customers]); // Only depend on customers

  const handleAddCustomer = async () => {
    if (!newCustomer.name || !newCustomer.phoneNumber) {
      setSnackbar({ open: true, message: 'Por favor rellene todos los campos', severity: 'error' });
      return;
    }
  
    setAddingCustomer(true);
    try {
      await axios.post('https://app20240719163201.azurewebsites.net/api/Customer', newCustomer, {
        headers: { 'Authorization': `Bearer ${authData.token}` }
      });
      setNewCustomer({ name: '', phoneNumber: '', debt: 0 }); // Note: changed contactInfo to phoneNumber
      fetchCustomers();
      setSnackbar({ open: true, message: 'Cliente ingresado exitosamente', severity: 'success' });
    } catch (error) {
      console.error('Error añadiendo cliente:', error);
      
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 409) {
          // This is the duplicate customer case
          setSnackbar({ open: true, message: error.response.data.message || 'Cliente duplicado', severity: 'warning' });
        } else {
          setSnackbar({ open: true, message: error.response.data.message || 'Error añadiendo cliente', severity: 'error' });
        }
      } else if (error.request) {
        // The request was made but no response was received
        setSnackbar({ open: true, message: 'No se recibió respuesta del servidor', severity: 'error' });
      } else {
        // Something happened in setting up the request that triggered an Error
        setSnackbar({ open: true, message: 'Error en la solicitud', severity: 'error' });
      }
    } finally {
      setAddingCustomer(false);
    }
  };



  const handleUpdateCustomer = async () => {
    setConfirmDialog({
      open: true,
      title: 'Confirmar modificación de cliente',
      content: `¿Está seguro que desea modificar los datos de ${originalCustomer?.name}?`,
      action: async () => {
        try {
          await axios.put(
            `https://app20240719163201.azurewebsites.net/api/Customer/${selectedCustomer.id}`,
            {
              name: selectedCustomer.name,
              phoneNumber: selectedCustomer.phoneNumber,
              debt: parseFloat(selectedCustomer.debt)
            },
            {
              headers: { 
                'Authorization': `Bearer ${authData.token}`,
                'Content-Type': 'application/json'
              }
            }
          );
          setOpenDialog(false);
          fetchCustomers();
          setSnackbar({ open: true, message: 'Cliente actualizado', severity: 'success' });
        } catch (error) {
          console.error('Error al actualizar el cliente:', error);
          setSnackbar({ open: true, message: 'Error al actualizar', severity: 'error' });
        }
      }
    });
  };

  const handleProcessPayment = async () => {
    setConfirmDialog({
      open: true,
      title: 'Confirmar Abono',
      content: `Esta seguro que desea realizar el abono ${formatChileanPesos(parseFloat(paymentAmount))} para ${selectedCustomer?.name}?`,
      action: async () => {
        setProcessingPayment(true);
        try {
          await axios.post(
            `https://app20240719163201.azurewebsites.net/api/Customer/${selectedCustomer.id}/payments`,
            { amount: parseFloat(paymentAmount) },
            {
              headers: { 
                'Authorization': `Bearer ${authData.token}`,
                'Content-Type': 'application/json'
              }
            }
          );
          setOpenPaymentDialog(false);
          fetchCustomers();
          setSnackbar({ open: true, message: 'Abono procesado exitosamente', severity: 'success' });
        } catch (error) {
          console.error('Error al procesar pago:', error);
          setSnackbar({ open: true, message: 'Error procesando pago', severity: 'error' });
        } finally {
          setProcessingPayment(false);
        }
      }
    });
  };

  const fetchPaymentHistory = async (customerId) => {
    try {
      const response = await axios.get(
        `https://app20240719163201.azurewebsites.net/api/Customer/${customerId}/payments`,
        {
          headers: { 'Authorization': `Bearer ${authData.token}` }
        }
      );
      setPaymentHistory(response.data);
    } catch (error) {
      console.error('Error fetching payment history:', error);
      setSnackbar({ open: true, message: 'Error fetching payment history', severity: 'error' });
    }
  };

  const filteredCustomers = customers.filter(customer => 
    customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    customer.phoneNumber.toLowerCase().includes(searchTerm.toLowerCase())
  );



    const formatChileanPesos = (value) => {
    return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  const sortedCustomers = React.useMemo(() => {
    const compareFunction = (a, b) => {
      if (orderBy === 'debt') {
        return order === 'asc' ? a.debt - b.debt : b.debt - a.debt;
      } else {
        return order === 'asc'
          ? a[orderBy].localeCompare(b[orderBy])
          : b[orderBy].localeCompare(a[orderBy]);
      }
    };
    return [...filteredCustomers].sort(compareFunction);
  }, [filteredCustomers, order, orderBy]);

  const adjustDate = (dateString) => {
    const date = new Date(dateString);
    date.setHours(date.getHours() - 4);
    return date;
  };
  
  // Helper function to format the date
  const formatDate = (dateString) => {
    const adjustedDate = adjustDate(dateString);
    return adjustedDate.toLocaleString('es-CL', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit',
      hour12: true 
    });
  };



  const CustomerRow = ({ customer, onEdit, onPayment, onHistory }) => (
    <Box 
      sx={{
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        padding: '16px',
        marginBottom: '16px',
        backgroundColor: '#fff',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="subtitle1" fontWeight="bold">{customer.name}</Typography>
          <Typography variant="body2" color="textSecondary">{customer.phoneNumber}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="subtitle1" fontWeight="bold">Deuda</Typography>
          <Typography variant="body2">{formatChileanPesos(customer.debt)}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display="flex" justifyContent={{xs: 'flex-start', md: 'flex-end'}} mt={{xs: 2, md: 0}}>
            <Tooltip title="Modificar cliente">
              <IconButton onClick={() => onEdit(customer)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Realizar abono">
              <IconButton onClick={() => onPayment(customer)}>
                <MoneyIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Historial de abonos">
              <IconButton onClick={() => onHistory(customer)}>
                <HistoryIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <StyledContainer maxWidth="lg">
        <Fade in={true} timeout={1000}>
          <Box>
            <Typography variant="h4" gutterBottom color="primary" fontWeight="bold" 
                        style={{ display: 'flex', alignItems: 'center', marginBottom: '2rem' }}>
              <PersonAddIcon fontSize="large" style={{ marginRight: '10px' }} />
              Gestión de Clientes
            </Typography>
            
            <StyledPaper elevation={3}>
              <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', color: theme.palette.primary.main }}>
                Agregar un nuevo cliente
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Nombre"
                    value={newCustomer.name}
                    onChange={(e) => setNewCustomer({...newCustomer, name: e.target.value})}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Número de teléfono"
                    value={newCustomer.phoneNumber}
                    onChange={(e) => setNewCustomer({...newCustomer, phoneNumber: e.target.value})}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    onClick={handleAddCustomer}
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={addingCustomer}
                    startIcon={addingCustomer ? <CircularProgress size={20} /> : <PersonAddIcon />}
                    style={{ height: '56px' }}
                  >
                    {addingCustomer ? 'Añadiendo...' : 'Ingresar cliente'}
                  </Button>
                </Grid>
              </Grid>
            </StyledPaper>
  
            <StyledPaper elevation={3}>
              <Typography variant="h6" gutterBottom style={{ color: theme.palette.primary.main }}>
                Lista de clientes
              </Typography>
              <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 'bold', color: theme.palette.secondary.main }}>
                Deuda total: {formatChileanPesos(totalDebt)}
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Buscar clientes..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="action" />
                    </InputAdornment>
                  ),
                }}
                style={{ marginBottom: '1rem' }}
              />
  <Box mb={2}>
  <Button
    onClick={() => handleRequestSort('name')}
    startIcon={orderBy === 'name' ? (order === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />) : null}
    style={{ marginRight: '10px' }}
  >
    Ordenar por nombre {orderBy === 'name' && (order === 'asc' ? '(A-Z)' : '(Z-A)')}
  </Button>
  <Button
    onClick={() => handleRequestSort('debt')}
    startIcon={orderBy === 'debt' ? (order === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />) : null}
  >
    Ordenar por deuda {orderBy === 'debt' && (order === 'asc' ? '(Menor a Mayor)' : '(Mayor a Menor)')}
  </Button>
</Box>
              {loading ? (
                <Box display="flex" justifyContent="center" my={4}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {sortedCustomers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((customer) => (
                      <CustomerRow 
                        key={customer.id} 
                        customer={customer}
                        onEdit={(customer) => {
                          setSelectedCustomer(customer);
                          setOriginalCustomer(customer);
                          setOpenDialog(true);
                        }}
                        onPayment={(customer) => {
                          setSelectedCustomer(customer);
                          setOpenPaymentDialog(true);
                        }}
                        onHistory={(customer) => {
                          setSelectedCustomer(customer);
                          fetchPaymentHistory(customer.id);
                          setOpenPaymentHistoryDialog(true);
                        }}
                      />
                    ))}
                  <Box mt={2}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={sortedCustomers.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Box>
                </>
              )}
            </StyledPaper>
  
            <Dialog 
              open={openDialog} 
              onClose={() => setOpenDialog(false)}
              PaperProps={{
                style: {
                  borderRadius: '12px',
                  padding: '16px',
                },
              }}
            >
              <DialogTitle>
                <EditIcon style={{ verticalAlign: 'middle', marginRight: '10px' }} />
                Actualizar cliente: {selectedCustomer?.name}
              </DialogTitle>
              <DialogContent>
                <TextField
                  label="Nombre"
                  value={selectedCustomer?.name || ''}
                  onChange={(e) => setSelectedCustomer({...selectedCustomer, name: e.target.value})}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  label="Número de teléfono"
                  value={selectedCustomer?.phoneNumber || ''}
                  onChange={(e) => setSelectedCustomer({...selectedCustomer, phoneNumber: e.target.value})}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  label="Deuda"
                  type="number"
                  value={selectedCustomer?.debt || ''}
                  onChange={(e) => setSelectedCustomer({...selectedCustomer, debt: e.target.value})}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenDialog(false)} color="secondary">Cancelar</Button>
                <Button onClick={handleUpdateCustomer} color="primary" variant="contained" style={{ marginLeft: '8px' }}>
                  Actualizar
                </Button>
              </DialogActions>
            </Dialog>
  
            <Dialog 
              open={openPaymentDialog} 
              onClose={() => setOpenPaymentDialog(false)}
              PaperProps={{
                style: {
                  borderRadius: '12px',
                  padding: '16px',
                },
              }}
            >
              <DialogTitle>
                <MoneyIcon style={{ verticalAlign: 'middle', marginRight: '10px' }} />
                Procesar abono para: {selectedCustomer?.name}
              </DialogTitle>
              <DialogContent>
                <Typography variant="body1" gutterBottom>
                  Deuda actual: {formatChileanPesos(selectedCustomer?.debt || 0)}
                </Typography>
                <TextField
                  label="Monto del abono"
                  type="number"
                  value={paymentAmount}
                  onChange={(e) => {
                    const value = e.target.value;
                    setPaymentAmount(value);
                    if (parseFloat(value) > selectedCustomer?.debt) {
                      setSnackbar({
                        open: true,
                        message: 'El monto excede la deuda',
                        severity: 'warning'
                      });
                    }
                  }}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
                {parseFloat(paymentAmount) > selectedCustomer?.debt && (
                  <Typography color="error">
                    Warning: El monto excede la deuda
                  </Typography>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenPaymentDialog(false)} color="secondary">Cancelar</Button>
                <Button 
                  onClick={handleProcessPayment} 
                  color="primary" 
                  variant="contained"
                  disabled={parseFloat(paymentAmount) > selectedCustomer?.debt || processingPayment}
                  style={{ marginLeft: '8px' }}
                >
                  {processingPayment ? 'Procesando...' : 'Abonar'}
                </Button>
              </DialogActions>
            </Dialog>
  
            <Dialog 
              open={openPaymentHistoryDialog} 
              onClose={() => setOpenPaymentHistoryDialog(false)}
              PaperProps={{
                style: {
                  borderRadius: '12px',
                  padding: '16px',
                },
              }}
            >
              <DialogTitle>Historial de pago de: {selectedCustomer?.name}</DialogTitle>
              <DialogContent>
                {paymentHistory.length > 0 ? (
                  <List>
                    {paymentHistory.map((payment, index) => (
                      <ListItem key={index}>
                        <ListItemText 
                          primary={`Monto: ${formatChileanPesos(payment.amount)}`}
                          secondary={`Date: ${formatDate(payment.paymentDate)}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography>Sin información de pagos</Typography>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenPaymentHistoryDialog(false)} color="primary">Cerrar</Button>
              </DialogActions>
            </Dialog>
  
            <Dialog 
              open={confirmDialog.open} 
              onClose={() => setConfirmDialog({ ...confirmDialog, open: false })}
              PaperProps={{
                style: {
                  borderRadius: '12px',
                  padding: '16px',
                },
              }}
            >
              <DialogTitle>{confirmDialog.title}</DialogTitle>
              <DialogContent>{confirmDialog.content}</DialogContent>
              <DialogActions>
                <Button onClick={() => setConfirmDialog({ ...confirmDialog, open: false })} color="secondary">
                  Cancelar
                </Button>
                <Button onClick={() => {
                  confirmDialog.action();
                  setConfirmDialog({ ...confirmDialog, open: false });
                }} color="primary" variant="contained" style={{ marginLeft: '8px' }}>
                  Confirmar
                </Button>
              </DialogActions>
            </Dialog>
  
            <Snackbar 
              open={snackbar.open} 
              autoHideDuration={6000} 
              onClose={() => setSnackbar({ ...snackbar, open: false })}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Alert 
                onClose={() => setSnackbar({ ...snackbar, open: false })} 
                severity={snackbar.severity} 
                variant="filled"
                style={{ borderRadius: '8px' }}
              >
                {snackbar.message}
              </Alert>
            </Snackbar>
          </Box>
        </Fade>
      </StyledContainer>
    </ThemeProvider>
  );
};

export default CustomerManagement;