import React, { useContext } from 'react';
import { AppBar, Toolbar as MuiToolbar, Typography, IconButton, Box, useTheme, Avatar, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AuthContext } from '../../context/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: 'linear-gradient(135deg, #1a73e8 0%, #0a4a8a 100%)',

  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease',
}));

const StyledToolbar = styled(MuiToolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(1, 3),
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 700,
  letterSpacing: '0.5px',
  color: theme.palette.common.white,
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.1)',
  },
}));

const LogoIcon = styled(StorefrontIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  fontSize: '2rem',
  color: theme.palette.common.white,
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

const UserInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.1)',
  },
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5, 1),
}));

const UserName = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  fontWeight: 500,
  color: theme.palette.common.white,
}));

const Header = ({ onLogout, onMenuClick }) => {
  const { authData } = useContext(AuthContext);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    onLogout();
  };

  return (
    <AppBarStyled position="fixed">
      <StyledToolbar>
        <Box display="flex" alignItems="center">
          <StyledIconButton 
            edge="start" 
            aria-label="menu" 
            onClick={onMenuClick}
            sx={{ marginRight: theme.spacing(2) }}
          >
            <MenuIcon />
          </StyledIconButton>
          <Title variant="h5" component="div">
            <LogoIcon />
            <Box component="span" sx={{ 
              display: { xs: 'none', sm: 'inline' },
              marginLeft: theme.spacing(1),
            }}>
              Gestion mi tienda
            </Box>
            <Box component="span" sx={{ display: { xs: 'inline', sm: 'none' } }}>
              GMT
            </Box>
          </Title>
        </Box>
        {authData.token && (
          <UserInfo onClick={handleMenu}>
            <Avatar sx={{ width: 32, height: 32, bgcolor: theme.palette.secondary.main }}>
              {authData?.username[0].toUpperCase()}
            </Avatar>
            <UserName>{authData?.username}</UserName>
            <ArrowDropDownIcon sx={{ color: theme.palette.common.white }} />
          </UserInfo>
        )}
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem> */}
          <MenuItem onClick={handleLogout}>Cerrar sesion</MenuItem>
        </Menu>
      </StyledToolbar>
    </AppBarStyled>
  );
};

export default Header;