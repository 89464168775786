import React, { useState, useEffect, useContext } from 'react';
import { 
  Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  CircularProgress, Box, Chip, Tooltip, useTheme, useMediaQuery, Fade, Grid, Card, CardContent, IconButton,
  Tabs, Tab
} from '@mui/material';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import { styled } from '@mui/system';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ScaleIcon from '@mui/icons-material/Scale';
import Inventory2Icon from '@mui/icons-material/Inventory2';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(3),
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
  fontSize: '1rem',
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

const QuantityChip = styled(Chip)(({ theme, quantity, index }) => ({
  backgroundColor: 
    index === 0 ? theme.palette.warning.main :
    index === 1 ? theme.palette.info.main :
    index === 2 ? theme.palette.success.main :
    theme.palette.grey[500],
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const ProductCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[8],
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    '& .MuiTab-root': {
      minWidth: 'auto',
      padding: theme.spacing(1),
    },
  },
}));


const MostSoldProducts = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [tabValue, setTabValue] = useState(0);
  const [error, setError] = useState(null);
  const { authData } = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  useEffect(() => {
    const fetchMostSoldProducts = async () => {
      try {
        const response = await axios.get('https://app20240719163201.azurewebsites.net/api/Product/MostSold', {
          headers: { 'Authorization': `Bearer ${authData.token}` }
        });
        const sortedProducts = sortProducts(response.data);
        setProducts(sortedProducts);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching most sold products:', error);
        setError('Failed to fetch most sold products. Please try again.');
        setLoading(false);
      }
    };

    fetchMostSoldProducts();
  }, [authData.token]);
  

  const sortProducts = (productList) => {
    return productList.sort((a, b) => {
      if (!a.isSoldByWeight && b.isSoldByWeight) return -1;
      if (a.isSoldByWeight && !b.isSoldByWeight) return 1;
      
      if (a.isSoldByWeight && b.isSoldByWeight) {
        if (a.weightUnit === 'kg' && b.weightUnit === 'g') return -1;
        if (a.weightUnit === 'g' && b.weightUnit === 'kg') return 1;
      }
      
      return b.quantitySold - a.quantitySold;
    });
  };

  const formatQuantitySoldDisplay = (product) => {
    if (product.isSoldByWeight) {
      const [quantity, unit] = product.quantitySoldDisplay.split(' ');
      const formattedQuantity = parseFloat(quantity).toString();
      return `${formattedQuantity} ${unit}`;
    }
    return product.quantitySold.toString();
  };



  if (loading) return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <CircularProgress size={60} />
    </Box>
  );

  if (error) return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Typography color="error" variant="h6" component="div">
        <ErrorOutlineIcon fontSize="large" style={{ verticalAlign: 'middle', marginRight: theme.spacing(1) }} />
        {error}
      </Typography>
    </Box>
  );

  const renderProductTable = (productList) => (
    <StyledTableContainer component={Paper}>
      <Table>
        <StyledTableHead>
          <TableRow>
            <StyledHeaderCell>Ranking</StyledHeaderCell>
            <StyledHeaderCell>Nombre</StyledHeaderCell>
            <StyledHeaderCell>SKU</StyledHeaderCell>
            <StyledHeaderCell>Descripción</StyledHeaderCell>
            <StyledHeaderCell align="right">Cantidad vendida</StyledHeaderCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {productList.map((product, index) => (
            <StyledTableRow key={product.sku}>
              <StyledTableCell>
                {index < 3 ? (
                  <Tooltip title={`Top ${index + 1}`}>
                    <EmojiEventsIcon color={index === 0 ? 'warning' : index === 1 ? 'info' : 'success'} />
                  </Tooltip>
                ) : (
                  index + 1
                )}
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="body1" fontWeight="medium">
                  {product.name}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>{product.sku}</StyledTableCell>
              <StyledTableCell>
                <Tooltip title={product.description || 'No hay descripción disponible'} arrow>
                  <IconButton size="small">
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell align="right">
                <QuantityChip
                  label={formatQuantitySoldDisplay(product)}
                  quantity={product.quantitySold}
                  index={index}
                  size="small"
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );

  const renderProductCards = (productList) => (
    <Grid container spacing={2}>
      {productList.map((product, index) => (
        <Grid item xs={12} sm={6} md={4} key={product.sku}>
          <ProductCard>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                {index < 3 ? (
                  <Tooltip title={`Top ${index + 1}`}>
                    <EmojiEventsIcon color={index === 0 ? 'warning' : index === 1 ? 'info' : 'success'} fontSize="large" />
                  </Tooltip>
                ) : (
                  <Typography variant="h6">{index + 1}</Typography>
                )}
                <QuantityChip
                  label={formatQuantitySoldDisplay(product)}
                  quantity={product.quantitySold}
                  index={index}
                  size="small"
                />
              </Box>
              <Typography variant="h6" gutterBottom>{product.name}</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>SKU: {product.sku}</Typography>
              <Typography variant="body2" color="textSecondary" paragraph style={{ 
                overflow: 'hidden', 
                textOverflow: 'ellipsis', 
                display: '-webkit-box', 
                WebkitLineClamp: 2, 
                WebkitBoxOrient: 'vertical' 
              }}>
                {product.description || 'No hay descripción disponible'}
              </Typography>
            </CardContent>
          </ProductCard>
        </Grid>
      ))}
    </Grid>
  );

  const renderProductList = (productList) => {
    return isMobile || isTablet ? renderProductCards(productList) : renderProductTable(productList);
  };

  if (loading) return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <CircularProgress size={60} />
    </Box>
  );

  if (error) return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Typography color="error" variant="h6" component="div">
        <ErrorOutlineIcon fontSize="large" style={{ verticalAlign: 'middle', marginRight: theme.spacing(1) }} />
        {error}
      </Typography>
    </Box>
  );

  const weightBasedProducts = products.filter(product => product.isSoldByWeight);
  const nonWeightBasedProducts = products.filter(product => !product.isSoldByWeight);

  return (
    <StyledContainer maxWidth="lg">
      <Fade in={true} timeout={1000}>
        <Box>
          <Typography variant="h4" component="h1" gutterBottom color="primary" fontWeight="bold">
            <TrendingUpIcon fontSize="large" style={{ verticalAlign: 'middle', marginRight: theme.spacing(1) }} />
            Productos mas vendidos
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" paragraph>
            Mostrando productos de mayor rendimiento por volumen de ventas
          </Typography>
          
          <StyledTabs 
            value={tabValue} 
            onChange={(event, newValue) => setTabValue(newValue)} 
            centered
            variant={isMobile ? "fullWidth" : "standard"}
          >
            <Tab 
              icon={<Inventory2Icon />} 
              label={isMobile ? "" : "Todos los productos"} 
              aria-label="Todos los productos"
            />
            <Tab 
              icon={<ScaleIcon />} 
              label={isMobile ? "" : "Productos por peso"} 
              aria-label="Productos por peso"
            />
            <Tab 
              icon={<Inventory2Icon />} 
              label={isMobile ? "" : "Productos por unidad"} 
              aria-label="Productos por unidad"
            />
          </StyledTabs>

          {tabValue === 0 && renderProductList(products)}
          {tabValue === 1 && (
            <>
              <Typography variant="h5" gutterBottom align={isMobile ? "center" : "left"}>
                Productos vendidos por peso
              </Typography>
              {renderProductList(weightBasedProducts)}
            </>
          )}
          {tabValue === 2 && (
            <>
              <Typography variant="h5" gutterBottom align={isMobile ? "center" : "left"}>
                Productos vendidos por unidad
              </Typography>
              {renderProductList(nonWeightBasedProducts)}
            </>
          )}
        </Box>
      </Fade>
    </StyledContainer>
  );
};

export default MostSoldProducts;