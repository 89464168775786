import React from 'react';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';

const ChipContainer = styled('div')(({ theme, expirationDays }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  height: '32px',
  borderRadius: '16px',
  padding: '0 12px',
  backgroundColor: 
    expirationDays <= 7 ? theme.palette.error.main :
    expirationDays <= 14 ? theme.palette.warning.main :
    theme.palette.success.main,
  color: theme.palette.common.white,
}));

const ChipLabel = styled(Typography)({
  fontSize: '0.8125rem',
  lineHeight: 1.75,
  fontWeight: 'bold',
});

const ExpirationChip = ({ daysUntilExpiration }) => {
  return (
    <ChipContainer expirationDays={daysUntilExpiration}>
      <ChipLabel>{`${daysUntilExpiration} days`}</ChipLabel>
    </ChipContainer>
  );
};

export default ExpirationChip;