import React, { createContext, useState, useEffect } from 'react';


export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState({ token: null, username: null });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    if (token && username) {
      setAuthData({ token, username });
     
    }
    setLoading(false);
  }, []);

  const setAuthDataAndStore = async (token, username) => {
    setAuthData({ token, username });
    if (token && username) {
      localStorage.setItem('token', token);
      localStorage.setItem('username', username);
     
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('products');
    }
  };
  
 
  // const fetchProducts = async (token) => {
  //   try {
  //     const response = await axios.get('https://localhost:7276/api/Product', {
  //       headers: {
  //         'Authorization': `Bearer ${token}`
  //       }
  //     });
  //     localStorage.setItem('products', JSON.stringify(response.data));
  //   } catch (error) {
  //     console.error('Error fetching products:', error);
  //   }
  // };

  return (
    <AuthContext.Provider value={{ authData, setAuthData: setAuthDataAndStore, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
